#skills {
    margin-top: -10rem;
    margin-bottom: 6rem;
    color: black;
    height: auto; 
    
    

    i {
        font-size: 10rem;
    }

    ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 20px;
        list-style: none;
        padding-bottom: 1rem; 
        align-items: center;
        padding-bottom: 3rem;
    }


    
  @include respond(phone) {
    i { 
        margin:0;
    }
  }

}